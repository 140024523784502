import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getAllBacktest(context, params) {
      return new Promise((resolve, reject) => {
        axios.get('http://localhost:8881/backtester/backtest',
          {
            params,
            headers: {
              token: '1227c0d4-22d1-15ec-9721-2227ac130002',
            },
          })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    atualizar(context, payload) {
      return new Promise((resolve, reject) => {
        axios.put('http://localhost:8881/backtester/backtest/atualizar', payload,
          {
            headers: {
              token: '1227c0d4-22d1-15ec-9721-2227ac130002',
            },
          })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    executar(context, params) {
      return new Promise((resolve, reject) => {
        axios.put('http://localhost:8881/backtester/backtest/executar', null,
          {
            params,
            headers: {
              token: '1227c0d4-22d1-15ec-9721-2227ac130002',
            },
          })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    excluir(context, params) {
      return new Promise((resolve, reject) => {
        axios.delete('http://localhost:8881/backtester/backtest/excluir', {
          params,
          headers: {
            token: '1227c0d4-22d1-15ec-9721-2227ac130002',
          },
        })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    pausar(context, params) {
      return new Promise((resolve, reject) => {
        axios.put('http://localhost:8881/backtester/backtest/pausar', null, {
          params,
          headers: {
            token: '1227c0d4-22d1-15ec-9721-2227ac130002',
          },
        })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    velocidade(context, params) {
      return new Promise((resolve, reject) => {
        axios.put('http://localhost:8881/backtester/backtest/velocidade', null, {
          params,
          headers: {
            token: '1227c0d4-22d1-15ec-9721-2227ac130002',
          },
        })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    abortar(context, params) {
      return new Promise((resolve, reject) => {
        axios.put('http://localhost:8881/backtester/backtest/abortar', null, {
          params,
          headers: {
            token: '1227c0d4-22d1-15ec-9721-2227ac130002',
          },
        })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getBacktest(context, id) {
      return new Promise((resolve, reject) => {
        axios.get(`http://localhost:8881/backtester/backtest/consulta-id/${id}`, {
          headers: {
            token: '1227c0d4-22d1-15ec-9721-2227ac130002',
          },
        })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getControle(context, id) {
      return new Promise((resolve, reject) => {
        axios.get(`http://localhost:8881/backtester/backtest/controle/${id}`, {
          headers: {
            token: '1227c0d4-22d1-15ec-9721-2227ac130002',
          },
        })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

  },
}
